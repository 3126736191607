import React from 'react'
import PrivacyPolicy from '../components/Legal/PrivacyPolicy'
import ScrollToTop from '../components/General/ScrollToTop'

const PrivacyPolicyPage = () => {
  return (
    <>
    <ScrollToTop/>
    <PrivacyPolicy/>
    </>
  )
}

export default PrivacyPolicyPage