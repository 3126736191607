import React from 'react'
import TermsOfService from '../components/Legal/TermOfServices'
import ScrollToTop from '../components/General/ScrollToTop'

const TermsServicesPage = () => {
  return (
    <>
    <ScrollToTop/>
    <TermsOfService/>
    </>
  )
}

export default TermsServicesPage