import { styled } from "styled-components";
import { Link as LinkRouter } from 'react-router-dom'

export const ServerSectionContainer = styled.div`
   display: flex;
   flex-wrap: wrap;
   flex-direction: row;
   justify-content: space-evenly;
   height: auto;
   width: 1250px;

   @media screen and (max-width: 1250px) {
      width: 100%;
   }
`
const ServerCardContainer = styled.div`
   position: relative;
   width: 375px;
   text-align: center;
   height: auto;
   margin: 40px 50px;
   @media screen and (max-width: 1250px) {
      margin: 10px
   }
`

const ServerInfoWrapper = styled.div`
  position: relative;
   width: auto;
   text-align: center;
   height: auto;
   padding: 30px;
`

const ServerImage = styled.img`
   width: 100%;
   border-radius: 5%;
   margin-bottom: 20px;
   filter: drop-shadow(0 20px 4px rgba(0, 0, 0, 0.5));
   @media screen and (max-width: 600px) {
      width: 85%;
   }
`;

const ServerName = styled.h1`
   font-size: 32px;

   color: #E2E2E2;
   @media screen and (max-width: 600px) {
      font-size: 25px;
   }
`;

const ServerCost = styled.p`
   font-size: 20px;
   text-align: left;
   white-space: pre-line;
   color: #E2E2E2;
   font-weight: 500;
   @media screen and (max-width: 600px) {
      text-align: center;
      font-size: 16px;
   }
`;

const ServerStats = styled.p`
   font-size: 16px;
   text-align: left;
   white-space: pre-line;
   color: #E2E2E2;
   font-weight: 450;
   @media screen and (max-width: 600px) {
      text-align: center;
      font-size: 14px;
   }
`;

export const RequestBtn = styled.nav`
   display: flex;
   align-items: center;
   justify-content: center;
   margin-top: 55px;
   margin-bottom: 15px;
   @media screen and (max-width: 600px) {
      margin-top: 30px;
      margin-bottom: 8px;
   }
`

export const RequestBtnLinkReq = styled(LinkRouter)`
   border-radius: 12px;
   background: #CDE17E;
   color: #037568;
   padding: 14px 44px;
   font-size: 16px;
   font-weight: 700;
   outline: none;
   border: none;
   cursor: pointer;
   transition: all 0.1s ease-in-out;
   text-decoration: none;
   &:hover {
      transition: all 0.1s ease-in-out;
      background-color: #1b523c;
      color: #e2e2e2;
   }
`
export const CardBackground = styled.div`
   height: 78%;
   width: 100%;
   position: absolute;
   background-color: #1B523C;
   opacity: 40%;
   border-radius: 20px;
   bottom: 0;
   z-index: 0;
`

export const ProductCard = ({ img, name, cost, stats, storageClass, storage, memory, gpuCount}) => {
   const handleClickRequest = () => {
      // Encode the storageClass and set it as a query parameter in the URL
      const encodedName = encodeURIComponent(name);
      const encodedStorageClass = encodeURIComponent(storageClass);
      const encodedStorage = encodeURIComponent(storage);
      const encodedMemory = encodeURIComponent(memory);
      const encodedGpuCount = encodeURIComponent(gpuCount);
      window.location.href = `/request?product=${encodedName}&storageClass=${encodedStorageClass}&storage=${encodedStorage}&memory=${encodedMemory}&gpuCount=${encodedGpuCount}`;
    };


   return (
      <ServerCardContainer>
        <CardBackground/>
         <ServerInfoWrapper>
            <ServerImage src={img} />
            <ServerName>{name}</ServerName>
            <ServerCost>{cost}</ServerCost>
            <ServerStats>{stats}</ServerStats>
            <RequestBtn>
            <RequestBtnLinkReq onClick={handleClickRequest}>Request</RequestBtnLinkReq>
            </RequestBtn>
         </ServerInfoWrapper>
         
      </ServerCardContainer>
   )
}
