import React, {useState}from 'react'
import { AccordionWrapper,AccordionItem, AccordionQuestion, AccordionAnswer, ToggleIcon } from './FaqElements'; 
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import PageComponent from '../../General/PageComponent';
import { faqData } from './faqData';


const FaqSection = () => {

  const [openIndices, setOpenIndices] = useState([]);

  const toggleAccordion = (index) => {
    if (openIndices.includes(index)) {
      setOpenIndices(openIndices.filter((i) => i !== index));
    } else {
      setOpenIndices([...openIndices, index]);
    }
  };

  return (
    <PageComponent title = 'Frequently Asked Questions' 
    body = {
      <AccordionWrapper>
      {faqData.map((faq, index) => (
        <AccordionItem key={index}>
          <AccordionQuestion onClick={() => toggleAccordion(index)}>
            {faq.question}
            {openIndices.includes(index) ? (
              <ToggleIcon>
                <FaChevronUp />
              </ToggleIcon>
            ) : (
              <ToggleIcon>
                <FaChevronDown />
              </ToggleIcon>
            )}
          </AccordionQuestion>
          {openIndices.includes(index) && (
            <AccordionAnswer >{faq.answer}</AccordionAnswer>
          )}
        </AccordionItem>
      ))}
    </AccordionWrapper>
    }/>
  )
}

export default FaqSection;