import styled from 'styled-components'
import {Link as LinkRouter} from 'react-router-dom'
import logo from '../../../icons/logo.svg'
import {Link as LinkScroll} from 'react-scroll'

export const Nav = styled.nav`
    background: #037568;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem; 
    font-weight: 700;
    position: sticky;
    top: 0;
    z-index:10;

    @media screen and (max-width: 1100px){
        transition: 0.8s all ease;
    }
`

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 80px;
    z-index: 1;
    width: 100%;
`

export const NavBarWrap = styled.div`
    padding: 10px 24px;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    width: 1250px; //for demo change this to 100% to match with navbar

`
export const NavLogo = styled(LinkRouter)`
    justify-self: flex-start;
    cursor: pointer;
    display: flex;
    align-items: center;
`

export const Img = styled.img.attrs({
    src: `${logo}`
  })`
    height: 90px;
  `;

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px){
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #e2e2e2;
    }
`

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
   
    @media screen and (max-width: 768px){
        display: none;
    }
`

export const NavItem = styled.li`
    height: 50px;
    margin-right:5px;
    &:hover{
        transition: all 0.1s ease-in-out;
        color: #1b523c;
    }
`

export const NavLinks = styled(LinkScroll)`
    color: #e2e2e2;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 1rem 1rem;
    height: 100%;
    cursor: pointer;
    border-radius: 5px;

    &.active{
        border-bottom: 7px solid #e2e2e2;
    }
    &:hover{
        transition: all 0.1s ease-in-out;
        border-bottom: 7px solid rgba(226,226,226, 0.5);
        
    }
`


export const NavBtn = styled.nav`
    display: flex;
    align-items: center;

    margin: 0px 15px;

    @media screen and (max-width: 768px){
        display: none;
    }
`

export const NavBtnLinkReq = styled(LinkRouter)`
    border-radius: 10px;
    background: #b1cf5f;
    color: #037568;
    white-space: nowrap;
    padding: 10px 20px 10px 16px;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    text-decoration: none;

    &:hover{
        transition: all 0.1s ease-in-out;
        background-color: #1b523c;
        color: #e2e2e2;
    }
`

export const NavBtnLinkLogin = styled(LinkRouter)`
    border-radius: 10px;
    background: #e2e2e2;
    color: #037568;
    white-space: nowrap;
    padding: 10px 20px 10px 16px;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    text-decoration: none;

    &:hover{
        transition: all 0.1s ease-in-out;
        background-color: #1b523c;
        color: #e2e2e2;
    }
`

