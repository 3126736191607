import React from 'react'
import Request from '../components/Request/index'
import ScrollToTop from '../components/General/ScrollToTop'

const RequestPage = () => {
  return (
    <>
    <ScrollToTop/>
    <Request/>
    </>
  )
}

export default RequestPage