import React from 'react'
import {
  Nav,
  NavbarContainer,
  NavLogo,
  Img,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLinkReq,
  // NavBtnLinkLogin,
  NavBarWrap
} from './NavbarElements';
import { FaBars } from 'react-icons/fa'
import { useLocation } from 'react-router-dom';


const Navbar = ({toggle}) => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';


  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavBarWrap>
          <NavLogo to="/">
            <Img />
          </NavLogo>
          {isHomePage && ( // Conditionally render NavMenu on the homepage
          <>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          
              <NavMenu>
                <NavItem>
                  <NavLinks to="hero" smooth={true} duration={500} spy={true} offset={-75}>Home</NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks to="serverPrices" smooth={true} duration={500} spy={true} offset={-75}>Servers &#38; Prices</NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks to="about" smooth={true} duration={500} spy={true} offset={-75}>About</NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks to="contact" smooth={true} duration={500} spy={true} offset={-75}>Contact</NavLinks>
                </NavItem>
                <NavBtn>
                  <NavBtnLinkReq to="/request">Request</NavBtnLinkReq>
                </NavBtn>
                {/* <NavBtn>
                  <NavBtnLinkLogin to="/login">Login</NavBtnLinkLogin>
                </NavBtn> */}
              </NavMenu>
              </>
            )}
          </NavBarWrap>
        </NavbarContainer>
      </Nav>
    </>
  )
}

export default Navbar;