import React ,{ useState, useEffect } from 'react'
import { TermsOfServiceContainer, TermsOfServiceWrapper,TermsOfServiceTitle, TermsDivider, TermsOfServiceContent } from './TermsOfServicesElements'
const TermsOfService = () => {
    const [content, setContent] = useState('');

    useEffect(() => {
        async function fetchContent() {
            const response = await fetch(new URL('terms.txt', import.meta.url));
            const text = await response.text();
            const paragraphs = text.split('\n');
            setContent(paragraphs);
        }
      
        fetchContent();
      }, []);
      

  return (
    <TermsOfServiceContainer>
        <TermsOfServiceWrapper>
            <TermsOfServiceTitle>
                Terms of Services
            </TermsOfServiceTitle>
            <TermsDivider/>
            {content.length > 0 ? (
        content.map((paragraph, index) => (
          <TermsOfServiceContent key={index}>{paragraph}</TermsOfServiceContent>
        ))
      ) : (
        <TermsOfServiceContent>Loading...</TermsOfServiceContent>
      )}
        </TermsOfServiceWrapper> 
    </TermsOfServiceContainer>
  )
}

export default TermsOfService