import React from "react";
import PageComponent from "../../General/PageComponent";
import {
  ChartSectionWrapper,
  ChartSectionSubtitle,
  ChartSectionHeaderWrapper,
  ChartSectionBodySection,
  Table,
  TableRow,
  TableCell
} from "./ChartSectionElements";
import { competitorData } from "../ChartSection/competitorData";



const ChartSection = () => {
  const first = 0;
  const last = competitorData.length-1;

  return (
    <PageComponent
      title="Private On-Premise Cloud"
      dividerColor="transparent"
      body={
        <ChartSectionWrapper>
          <ChartSectionHeaderWrapper>
            <ChartSectionSubtitle>
              at a fraction of the price
            </ChartSectionSubtitle>
          </ChartSectionHeaderWrapper>
          <ChartSectionBodySection>
            <Table>
              {competitorData.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {Object.values(row).map((cell, cellIndex) => (
                    <TableCell
                      key={cellIndex}
                      isEmpty={cell === ""}
                      isHeader={
                        rowIndex === first && cellIndex !== first && cell !== ""
                      }
                      isFeature={
                        rowIndex !== first && cellIndex === first && cell !== ""
                      }
                      style={
                        (rowIndex === first && cellIndex === 1) || // For Header top-left corner
                        (rowIndex === 1 && cellIndex === first) || // For Feature top-left corner
                        (rowIndex === last && cellIndex === first) // For Feature bottom-left corner
                          ? {
                              borderTopLeftRadius:
                                (rowIndex === first && cellIndex === 1) ||
                                (rowIndex === 1 && cellIndex === first)
                                  ? "20px"
                                  : null,
                              borderBottomLeftRadius:
                                rowIndex === last && cellIndex === first
                                  ? "20px"
                                  : null,
                            }
                          : null
                      }
                    >
                      {cell}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </Table>
          </ChartSectionBodySection>
        </ChartSectionWrapper>
      }
    />
  );
};

export default ChartSection;
