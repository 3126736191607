import React from 'react'
import {
  HeroContainer,
  HeroWrapper,
  ContentWrapper,
  TextWrapper,
  Header,
  Paragraph,
  Blob,
  ImageWrapper,
  ButtonWrapper,
  Button,
} from './HeroElements'


const HeroSection = () => {
  return (
    <HeroContainer id='hero'>
      <HeroWrapper>
        <ContentWrapper>
          <TextWrapper>
            <Header>Don’t give Big Cloud your data</Header>
            <Paragraph>
              For a fraction of the cost of major cloud providers
              Lease private HPC machines to keep your models
              and data safe and secure
            </Paragraph>
          </TextWrapper>
          <ImageWrapper>
            <Blob />
          </ImageWrapper>
        </ContentWrapper>
        <ButtonWrapper>
          <Button to="serverPrices" smooth={true} duration={500} spy={true} offset={-75}>See Our Servers and Pricing</Button>
        </ButtonWrapper>
      </HeroWrapper>
    </HeroContainer>
  )
}

export default HeroSection
