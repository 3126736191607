export const faqData = [
  {
    question: "What is Deep Learning Rental?",
    answer: `Based out of Saint Paul, MN, Deep Learning Rental LLC (DLR) is a boutique datacenter providing rentable High Performance Computing (HPC) machines at an affordable price to companies looking to do machine learning, artificial intelligence, and video rendering work in addition to other uses that require high compute power.\n
    Our service includes maintenance and management of the rented machines to ensure an expected 99% reliability for our devices, as well as 1Gbps up/down fiber and all other associated costs.\n
    DLR provides private HPC machines for a fraction of the cost of major cloud providers and keeps your models and proprietary data safe and secure.\n
    By partnering with Deep Learning Rental, we can save you time, money, and effort. Our multi-GPU servers contain powerful NVIDIA GPUs from RTX 3090, NVIDIA V100s, NVIDIA A10s, NVIDIA A40s, up to multiple NVIDIA A100 (even A100x) compute units. Our servers meet the high requirements of massive parallel GPU computing applications.
        `,
  },
  {
    question: "What are the advantages of renting with Deep Learning Rental",
    answer: `We work hard to keep our overhead down so we can provide high quality HPC Machines at a fraction of the cost of other providers. We’re not some faceless company, we are three friends (and “daughter dads”) who are encouraging engagement with younger members of the tech community as well as the BIPOC community and women through our vendor contracts and company projects. All while  building a future for our families. \n
    Our organizational values of Family, Personalized Customer Service, Reliability, Affordability, Forward Thinking, and Restraint are visible in everything we do. When you need customer support, one of us answers the phone because when you work with us, you become a member of our family and our commitment to you is iron clad.
    `,
  },
  {
    question: "Is our data secure on your servers?",
    answer: `Unlike some providers, we do not look at, or hold on to your data during your rental or after. It’s your data and we are committed to its privacy and security.
We know your AI / ML team wants bare metal to run their workloads on because there’s nothing faster. We offer a white glove, bare metal HPC leasing service at a fraction of the cost of Big Cloud providers. This includes:\n
·         Dedicated bare metal machines
·         Multiple out-of-the-box configurations
·         Fast setup times and no setup costs
·         Static IP addresses and public domains
·         Total data privacy and top tier security
·         Multiple operation system options
·         Custom built virtual workstations `,
  },
  {
    question: "Rental Types",
    answer: `Deep Learning Rental currently only offers dedicated machine rentals by the day, week, or month. Payment is due in advance of your rental.
    `,
  },
  {
    question: "Can I get a trial to test a specific machine?",
    answer: `We do offer short free trials depending on the specific use-care you are working on. However, we can only do this for long-term potential rentals. Contact us at david@deeplearningrental.com for more information.
    `,
  },
  {
    question: "Are machines fully dedicated or are they spot instances?",
    answer: `All Deep Learning Rental machines are fully dedicated to you, 24/7, for the entirety of your rental period.
    `,
  },
  {
    question: "What payment methods do you accept?",
    answer: `We accept most major credit cards, bank transfers, PayPal, and Venmo.
    `,
  },
  {
    question: "What happens to my data when my rental ends?",
    answer: `At the end of your rental, once you have removed your data, we erase all data left on the HPC. Please make sure that you have saved and backed up any data you have on the server prior to ending your rental. Deep Learning Rental will not be held accountable for any data loss after your rental has ended.
    `,
  },
  {
    question: "Do you process our personal data or share it with a third party?",
    answer: `Your privacy and data security is critical to Deep Learning Rental. Under no circumstances do we process your data or will we share it with, or sell it to, a third party. If at any time you would like us to erase your data on a server, contact us at david@deeplearningrental.com.
    `,
  },
];
