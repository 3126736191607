import React from 'react';
import styled from 'styled-components';
import icon from '../../../icons/LogoIcon.svg';

export const AboutUsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1250px;
  
  height: auto;
  padding: 20px 30px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex:4;
  
  @media screen and (max-width: 850px){
    flex-direction: column;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex: 1.2;
  flex-direction: column;
  justify-content: center;
  color: #1B523C;
  opacity: 85%;
  padding-right: 60px;

  @media screen and (max-width: 600px){
    align-items: center;
    padding-right: 20px;
  }
`;

export const Paragraph = styled.p`
  font-size: clamp(12px, 18px, 20px);
  text-align: left;
  margin-left: 10px;
  font-weight: 400;
  margin-bottom: 8px;

  @media screen and (max-width: 600px){
    text-align: center;
    margin-bottom: 15px;
  }
`;


export const ImageWrapper = styled.div`
  flex: .75;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;

  @media screen and (max-width: 960px){

    margin-top: 40px;
  }
`;

export const Icon = styled.img.attrs({
  src: `${icon}`
})`
  height: 300px;
  max-width: auto;
   transform: scaleX(-1);
   opacity: 65%;

  @media screen and (max-width: 600px){
    max-width: 75%;
    height: auto;
  }
`;


export const MeetTheTeamContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: top;
  width: 1250px;

  @media screen and (max-width: 960px) {
    justify-content: center;
  }
`;

const CardContainer = styled.div`
  padding: 20px;
  width: 300px;
  text-align: center;
  height: auto;
  margin: 10px;

  @media screen and (max-width: 960px) {
    max-width: calc(55% - 20px);
  }

  @media screen and (max-width: 600px) {
    max-width: calc(100% - 20px);
  }
`;


const MemberImage = styled.img`
  width: 225px;
  height: 225px;
  border-radius: 5%;
  object-fit: cover;
  object-position: top;
`;

const MemberName = styled.h3`
  margin: 10px 0;
  font-size: 25px;
  color: #1B523C;
`;

const MemberDescription = styled.p`
  margin-top: 12px;
  font-size: 16px;
  text-align: left;
  white-space: pre-line;
  color: #1B523C;
  opacity: 85%;
  font-weight: 400;
  @media screen and (max-width: 600px){
    text-align: center;
  }
`;

export const TeamMemberCard = ({ imageSrc, name, description }) => {
  return (
    <CardContainer>
      <MemberImage src={imageSrc} />
      <MemberName>{name}</MemberName>
      <MemberDescription>{description}</MemberDescription>
    </CardContainer>
  );
};
