
import nvidia from '../../../imgs/NVIDIA_A100.png'

export const productData = [
  {
    id: 1,
    img: `${nvidia}`,
    name: "NVIDIA A100 40GB PCIE",
    cost: `
    $7,999 per Month
    $1,849 per Week
    $269 per Day
    `,
    storageClass: 'NVMe',
    storage: 'other',
    memory: '512GB',
    gpuCount: 8,
    stats: `
    · Total GPU: 8
    · Teraflops: 156.0 (Single-Precision)
    · 2x EPYC 7502 Processor
    · 512GB DDR4 3200 Memory
    · 15.3 TB NVMe Storage
    `,
  },
  {
    id: 2,
    img: ` ${nvidia}`,
    name: "NVIDIA A100 40GB PCIE",
    cost: `
    $3,999 per Month
    $929 per Week
    $139 per Day
    `,
    storageClass: 'NVMe',
    storage: '2tb',
    memory: '512GB',
    gpuCount: 4,
    stats: `
    · Total GPU: 4
    · Teraflops: 78.0 (Single-Precision)
    · EPYC 7B13 Processor
    · 512GB DDR4 Memory
    · 2 TB NVMe Storage
    `,
  },
  
  {
    id: 3,
    img: `${nvidia} `,
    name: "NVIDIA V100",
    cost: `
    $349 per Month
    $81 per Week
    $12 per Day
    `,
    storageClass: 'SSD',
    storage: 'other',
    memory: '64GB',
    gpuCount: 1,
    stats: `
    · Total GPU: 1
    · Teraflops: 13.2 (Single-Precision)
    · E5-2670 Processor
    · 64GB DDR3 Memory
    · 500 GB SSD + 2.5 TB Raid0 Storage
    `,
  },
  {
    id: 4,
    img: `${nvidia} `,
    name: "NVIDIA A40",
    cost: `
    $1,499 per Month
    $349 per Week
    $49 per Day
    `,
    storageClass: 'NVMe',
    storage: '2tb',
    memory: '256GB',
    gpuCount: 4,
    stats: `
    · Total GPU: 4
    · Teraflops: 149.6 (Single-Precision)
    · EPYC 7B11 Processor
    · 256GB DDR4 3200 Memory
    · 2 TB NVMe U.2 Storage
    `,
  },
  {
    id: 5,
    img: `${nvidia} `,
    name: "NVIDIA RTX 3090 (7282)",
    cost: `
    $849 per Month
    $199 per Week
    $29 per Day
    `,
    storageClass: 'NVMe',
    storage: '2tb',
    memory: '128GB',
    gpuCount: 2,
    stats: `
    · Total GPU: 2
    · Teraflops: 58.56 (Single-Precision)
    · EPYC 7282 Processor
    · 128GB DDR4 3200 Memory
    · 2 TB NVMe M.2 Storage
    `,
  },
  {
    id: 6,
    img: `${nvidia} `,
    name: "NVIDIA RTX 3090 (7302)",
    cost: `
    $849 per Month
    $199 per Week
    $29 per Day
    `,
    storageClass: 'NVMe',
    storage: '2tb',
    memory: '128GB',
    gpuCount: 2,
    stats: `
    · Total GPU: 2
    · Teraflops: 58.56 (Single-Precision)
    · EPYC 7302P Processor
    · 128GB DDR4 3200 Memory
    · 2 TB NVMe M.2 Storage
    `,
  },
  {
    id: 7,
    img: `${nvidia} `,
    name: "NVIDIA RTX 3090 (7502)",
    cost: `
    $1,699 per Month
    $399 per Week
    $59 per Day
    `,
    storageClass: 'NVMe',
    storage: '2tb',
    memory:'256GB',
    gpuCount: 4,
    stats: `
    · Total GPU: 4
    · Teraflops: 117.1 (Single-Precision)
    · EPYC 7502 Processor
    · 256GB DDR4 3200 Memory
    · 2 TB NVMe M.2 Storage
    `,
  },
];
