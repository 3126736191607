import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import PageComponent from "../General/PageComponent";
import {
  BlobBackground,
  RequestPageWrapper,
  Form,
  FormRow,
  FormGroup,
  Label,
  RequiredSign,
  Input,
  DropDown,
  RadioInputWrapper,
  RadioLabel,
  RadioOptionsWrapper,
  RadioInput,
  Button,
  RequestCompleteBody,
  RequestCompleteText,
} from "./RequestElements";
import { productData } from "../Home/ServicesPricesSection/productData";

import { useForm } from "@formspree/react";

const Request = () => {
  const [selectedOption, setSelectedOption] = useState("no");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const gpuModels = productData.map((product) => product.name); // Extract product names
  const uniqueMemoryValues = [
    ...new Set(productData.map((product) => product.memory)),
  ];
  const uniqueGpuCountValues = [
    ...new Set(productData.map((product) => product.gpuCount)),
  ];

  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    model: queryParams.get("product") || "",
    count: queryParams.get("gpuCount") || "",
    storage: queryParams.get("storage") || "",
    storageClass: queryParams.get("storageClass") || "",
    cores: "",
    memory: queryParams.get("memory") || "",
    bandwidth: "no",
    gbps: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleBothChanges = (e) => {
    handleRadioChange(e);
    handleChange(e);
  };

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const [state, handleSubmit] = useForm("xyyqllro");
  if (state.succeeded) {
    return (
      <PageComponent
        title="Request Page"
        body={
          <RequestPageWrapper>
            <RequestCompleteBody>
              <RequestCompleteText>
                Your request has been sent.
              </RequestCompleteText>
              <RequestCompleteText>
                We will get back to you shortly!
              </RequestCompleteText>
            </RequestCompleteBody>
          </RequestPageWrapper>
        }
      />
    );
  }

  return (
    <PageComponent
      title="Request Page"
      body={
        <RequestPageWrapper>
          <Form onSubmit={handleSubmit}>
            <FormRow>
              <FormGroup>
                <Label>Name</Label>
                <RequiredSign />
                <Input
                  type="text"
                  name="fname"
                  value={formData.fname}
                  onChange={handleChange}
                  placeholder="First"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  name="lname"
                  value={formData.lname}
                  onChange={handleChange}
                  placeholder="Last"
                  required
                />
              </FormGroup>
            </FormRow>

            <FormRow>
              <FormGroup>
                <Label>Email Address</Label>
                <RequiredSign />
                <Input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="abc@gmail.com"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label>Phone Number</Label>
                <RequiredSign />
                <small style={{ marginLeft: 15 }}>+01-123-456-789</small>
                <Input
                  type="message"
                  name="phone"
                  placeholder="+01-123-456-7890"
                  pattern="+[0-9]{2}-[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  maxlength="16"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </FormRow>

            <FormRow>
              <FormGroup>
                <Label>GPU Model</Label>
                <DropDown
                  name="model"
                  value={formData.model}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {gpuModels.map((model, index) => (
                    <option key={index} value={model}>
                      {model}
                    </option>
                  ))}
                </DropDown>
              </FormGroup>
              <FormGroup>
                <Label>GPU Count</Label>
                <DropDown
                  name="count"
                  value={formData.count}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {uniqueGpuCountValues.map((gpuCount, index) => (
                    <option
                      key={index}
                      value={gpuCount}
                      selected={gpuCount === formData.count} // Set the 'selected' attribute
                    >
                      {gpuCount}
                    </option>
                  ))}
                </DropDown>
              </FormGroup>
            </FormRow>
            <FormRow>
              <FormGroup>
                <Label>Storage</Label>
                <RadioOptionsWrapper>
                  <RadioInputWrapper>
                    <RadioInput
                      type="radio"
                      name="storage"
                      value="1tb"
                      checked={formData.storage === "1tb"}
                      onChange={handleChange}
                    />
                    <RadioLabel for="1tb">1 TB</RadioLabel>
                  </RadioInputWrapper>
                  <RadioInputWrapper>
                    <RadioInput
                      type="radio"
                      name="storage"
                      value="2tb"
                      checked={formData.storage === "2tb"}
                      onChange={handleChange}
                    />
                    <RadioLabel for="2tb">2 TB</RadioLabel>
                  </RadioInputWrapper>
                  <RadioInputWrapper>
                    <RadioInput
                      type="radio"
                      name="storage"
                      value="4tb"
                      checked={formData.storage === "4tb"}
                      onChange={handleChange}
                    />
                    <RadioLabel for="4tb">4 TB</RadioLabel>
                  </RadioInputWrapper>
                  <RadioInputWrapper>
                    <RadioInput
                      type="radio"
                      name="storage"
                      value="8tb"
                      checked={formData.storage === "8tb"}
                      onChange={handleChange}
                    />
                    <RadioLabel for="8tb">8 TB</RadioLabel>
                  </RadioInputWrapper>
                  <RadioInputWrapper>
                    <RadioInput
                      type="radio"
                      name="storage"
                      value="16tb"
                      checked={formData.storage === "16tb"}
                      onChange={handleChange}
                    />
                    <RadioLabel for="16tb">16 TB</RadioLabel>
                  </RadioInputWrapper>
                  <RadioInputWrapper>
                    <RadioInput
                      type="radio"
                      name="storage"
                      value="other"
                      checked={formData.storage === "other"}
                      onChange={handleChange}
                    />
                    <RadioLabel for="other">Other</RadioLabel>
                  </RadioInputWrapper>
                </RadioOptionsWrapper>
              </FormGroup>
            </FormRow>
            <FormRow>
              <FormGroup>
                <Label>Storage Class</Label>
                <RadioOptionsWrapper>
                  <RadioInputWrapper>
                    <RadioInput
                      type="radio"
                      name="storageClass"
                      value="ssd"
                      checked={formData.storageClass === "SSD"}
                      onChange={handleChange}
                    />
                    <RadioLabel for="ssd">SDD</RadioLabel>
                  </RadioInputWrapper>
                  <RadioInputWrapper>
                    <RadioInput
                      type="radio"
                      name="storageClass"
                      value="nvme"
                      checked={formData.storageClass === "NVMe"}
                      onChange={handleChange}
                    />
                    <RadioLabel for="nvme">NVMe</RadioLabel>
                  </RadioInputWrapper>
                </RadioOptionsWrapper>
              </FormGroup>
            </FormRow>

            <FormRow>
              <FormGroup>
                <Label>Cores</Label>
                <DropDown
                  name="cores"
                  value={formData.cores}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="8cores">8</option>
                  <option value="16cores">16</option>
                  <option value="32cores">32</option>
                  <option value="64cores">64</option>
                  <option value="128cores">128</option>
                </DropDown>
              </FormGroup>
              <FormGroup>
                <Label>Memory</Label>
                <DropDown
                  name="memory"
                  value={formData.memory}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {uniqueMemoryValues.map((memory, index) => (
                    <option key={index} value={memory}>
                      {memory}
                    </option>
                  ))}
                </DropDown>
              </FormGroup>
            </FormRow>

            <FormRow>
              <FormGroup>
                <Label>Dedicated Fiber Bandwidth</Label>
                <RadioOptionsWrapper>
                  <RadioInputWrapper>
                    <RadioInput
                      type="radio"
                      name="bandwidth"
                      value="yes"
                      checked={
                        formData.bandwidth === "yes" && selectedOption === "yes"
                      }
                      onChange={handleBothChanges}
                    />
                    <RadioLabel for="yes">Yes</RadioLabel>
                  </RadioInputWrapper>
                  <RadioInputWrapper>
                    <RadioInput
                      type="radio"
                      name="bandwidth"
                      value="no"
                      checked={
                        formData.bandwidth === "no" && selectedOption === "no"
                      }
                      onChange={handleBothChanges}
                    />
                    <RadioLabel for="no">No</RadioLabel>
                  </RadioInputWrapper>
                </RadioOptionsWrapper>
              </FormGroup>
              {selectedOption === "yes" && (
                <FormGroup>
                  <Label>If yes: How many gbps?</Label>
                  <RequiredSign />
                  <Input
                    type="text"
                    name="gbps"
                    placeholder="Type here"
                    value={formData.gbps}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              )}
            </FormRow>
            <Button type="submit" disabled={state.submitting}>
              Submit
            </Button>
          </Form>

          <BlobBackground />
        </RequestPageWrapper>
      }
    />
  );
};

export default Request;
