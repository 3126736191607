import FaqSection from '../components/Home/FaqSection'
import HeroSection from '../components/Home/HeroSection'
import ContactSection from '../components/Home/ContactSection'
import AboutUsSection from '../components/Home/AboutUsSection'
import ServersPricingSection from '../components/Home/ServicesPricesSection'
import ChartSection from '../components/Home/ChartSection'
import React from "react";
import ScrollToTop from '../components/General/ScrollToTop'

const Home = () => {
 
  
  return (
    <>
      <ScrollToTop/>
         <HeroSection id='hero'/>
         <ChartSection/>
         <ServersPricingSection id="serverPrices"/>
         <AboutUsSection id="about"/>
         <ContactSection id="contact"/>
        <FaqSection id="faq"/>  
    </>
  )
}

export default Home
