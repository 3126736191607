import Hatch from '../../../imgs/Hatch.png';
import Kaplan from '../../../imgs/Kaplan.png';
import DeBouche from '../../../imgs/DeBouche.png';


export const teamMembers = [
    {
      id: 1,
      imageSrc: `${Hatch}`,
      name: 'Jonathan Hatch',
      description: `Co-Founder and CEO

      Jonathan serves as CEO and CTO of Deep Learning Rental. 
        
      When not working on technology projects, you can find him spending time with his family.`,
    },
    {
      id: 2,
      imageSrc: `${Kaplan}`,
      name: 'David Kaplan',
      description: `Co-Founder and COO

      David manages the business side of Deep Learning Rental and keeps the trains running on time. 
      
      When not reminding Jonathan about the current checklist or sharing an updated spreadsheet, you can find him going to the zoo with his kids, 
      cleaning up their playroom, and rockn’ out to some Bon Jovi.`,
    },
    {
        id: 3,
        imageSrc: `${DeBouche}`,
        name: 'Chad DeBouche',
        description: `CIO of Deep Learning Rental
  
        Outside of his busy daily work schedule, he gets to spend even more time being a Dance Dad to his daughter.`,
      },
  ];