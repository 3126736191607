import React from 'react';
import styled from 'styled-components';

const Page = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: auto;
  z-index: 0;
`;

const PageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    width: 100%;
    margin: 75px 0;
`

const PageHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 1250px;
  padding: 0px 30px;
  margin-bottom: 15px;

  @media screen and (max-width: 1250px){
    display: block;
    width: 100%;
    height: auto;
  }
`

const PageBodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 1250px;

  @media screen and (max-width: 1250px){
    width: 100%;
  }
`
const PageHeaderTitle = styled.h1`
  color: #1B523C;
  display: flex;
  white-space: nowrap;
  margin-right: 20px;
  font-size: 40px;

  @media screen and (max-width: 1200px) {
    margin-right: 0;
    white-space: normal;
  }

  @media screen and (max-width: 520px) {
    margin-right: 0;
    white-space: normal;
    font-size: 28px;
  }
`

const PageHeaderBanner = styled.div`
  opacity: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10px;
  width: 100%;
  border-radius: 5px;

  @media screen and (max-width: 520px) {
    height: 8px;
  }
`

const PageComponent = ({id, title ,dividerColor = "#1B523C", body }) => {
    return (
     <Page id = {id}>
        <PageWrapper>
            <PageHeaderWrapper>
                <PageHeaderTitle>{title}</PageHeaderTitle>
                <PageHeaderBanner style={{backgroundColor: dividerColor}}/>
            </PageHeaderWrapper>
            <PageBodyWrapper>{body}</PageBodyWrapper>
        </PageWrapper>
     </Page>
    );
  };
  
  export default PageComponent