export const competitorData = [
  {
    feature: '',
    lambda: 'Lambda',
    coreWeave: 'CoreWeave',
    aws: 'AWS',
    dlr: 'DLR',
    
  },
  
  {
    feature: 'GPU',
    lambda: '1xA100 PCIE 40 GB',
    coreWeave: '1xA100 PCIE 40 GB',
    aws: '1xA100 PCIE 40 GB',
    dlr: '1xA100 PCIE 40 GB',
  },

  {
    feature: 'Cores',
    lambda: '30',
    coreWeave: '94',
    aws: '96',
    dlr: '64',
  },

  {
    feature: 'Storage',
    lambda: '516 GB',
    coreWeave: '1 TB',
    aws: '8 TB',
    dlr: '15 TB NVMe',
  },

  {
    feature: 'Dedicated, Low Latency Fiber',
    lambda: 'No',
    coreWeave: 'No',
    aws: 'No',
    dlr: 'Yes',
  },

  {
    feature: 'Personal Customer Service',
    lambda: 'No',
    coreWeave: 'No',
    aws: 'No',
    dlr: 'Yes',
  },

  {
    feature: 'Price (Per Hour)',
    lambda: '$1.10',
    coreWeave: '$2.06',
    aws: '$3.80',
    dlr: '$1.37',
  }, 

]