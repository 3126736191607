import React, {useState, useEffect} from 'react'
import { PrivacyPolicyContainer, PrivacyPolicyWrapper, PrivacyPolicyTitle, PrivacyPolicyDivider, PrivacyPolicyContent } from './PrivacyPolicyElements';


const PrivacyPolicy = () => {

  const [content, setContent] = useState('');

    useEffect(() => {
        async function fetchContent() {
            const response = await fetch(new URL('privacy.txt', import.meta.url));
            const text = await response.text();
            const paragraphs = text.split('\n');
            setContent(paragraphs);
        }
      
        fetchContent();
      }, []);

return (
  <PrivacyPolicyContainer>
      <PrivacyPolicyWrapper>
          <PrivacyPolicyTitle>
              Privacy Policy
          </PrivacyPolicyTitle>
          <PrivacyPolicyDivider/>
          {content.length > 0 ? (
        content.map((paragraph, index) => (
          <PrivacyPolicyContent key={index}>{paragraph}</PrivacyPolicyContent>
        ))
      ) : (
        <PrivacyPolicyContent>Loading...</PrivacyPolicyContent>
      )}
      </PrivacyPolicyWrapper> 
  </PrivacyPolicyContainer>
)
};

export default PrivacyPolicy