import React from 'react';
import styled from 'styled-components';

const BgColorContainer = styled.div`
  position: relative;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(119,174,146, 0.15); 
  z-index: -1;
`;

const BackgroundColor = ({ children }) => {
  return (
    <BgColorContainer>
      <Background />
      {children}
    </BgColorContainer>
  );
};

export default BackgroundColor;
