import styled from 'styled-components';
import blob from '../../../icons/blobCard.svg';
import {Link as LinkScroll} from 'react-scroll'

export const HeroContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 70vh;

  @media screen and (max-width: 600px) {
    height: auto;
  }
`;

export const HeroWrapper = styled.div`
  height: 450px;
  width: 1100px;
  display: flex;
  flex-direction: column;
 
  @media screen and (max-width: 600px) {
    padding: 20px;
    padding-top: 30px;
    height: auto;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex:4;
  
  @media screen and (max-width: 600px){
    flex-direction: column;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex: 1.2;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  color: #1B523C;
  padding-right: 60px;

  @media screen and (max-width: 600px){
    align-items: center;
    padding-right: 0px;
  }
`;

export const Header = styled.h1`
  font-size: 54px;
  text-align: right;
  margin-bottom: 20px;
  font-weight: 700;

  @media screen and (max-width: 600px){
    text-align: center;
    margin-bottom: 15px;
    font-size: 42px;
  }
`;

export const Paragraph = styled.p`
  font-size: 22px;
  text-align: right;
  margin-left: 40px;
  font-weight: 400;

  @media screen and (max-width: 600px){
    text-align: center;
    margin-bottom: 30px;
    margin-left: 0px;
  }
`;


export const ImageWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Blob = styled.img.attrs({
  src: `${blob}`
})`
  height: auto;
  max-width: 90%;

  @media screen and (max-width: 600px){
    max-width: 60%;
    margin-bottom:30px;
  }
`;





export const ButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 60px;
  margin-bottom: 50px;
  @media screen and (max-width: 600px){
    margin-top:30px;
  }
`;

export const Button = styled(LinkScroll)`
  border-radius: 10px;
  background: #037568;
  color: #e2e2e2;
  white-space: nowrap;
  padding: 14px 22px 14px 20px;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  text-decoration: none;
  font-weight: 700;

  &:hover {
    transition: all 0.1s ease-in-out;
    background-color: #CDE17E;
    color: #1B523C;
  }
`;

