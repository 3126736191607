import styled from 'styled-components';

export const AccordionWrapper = styled.div`
  width: 100%;
  padding: 20px 30px;
`;

export const AccordionItem = styled.div`
  margin-bottom: 25px;
`;

export const AccordionQuestion = styled.div`
  cursor: pointer;
  background-color: rgba(27, 82, 60, 0.65);
  padding: 14px 40px;
  border-radius: 15px;
  font-weight: 700;
  color: #E2E2E2;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 500px){
    padding: 5px 20px;
    font-size: 14px;
  }
`;

export const AccordionAnswer = styled.div`
  background-color: rgba(119, 174, 146, 0.35);
  color: #1B523C;
  padding: 18px 40px;
  border-radius: 15px;
  white-space: pre-line;
  font-weight: 500;
  font-size: 16px;

  @media screen and (max-width: 500px){
    padding: 9px 20px;
    font-size: 12px;
  }
`;

export const ToggleIcon = styled.span`
margin-left: auto;
font-size: 1.75rem;
font-family: 900;
margin-right: 40px;
color: #B1CF5F;
margin-left: 8px;
transition: transform 0.3s ease-in-out;
transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')}
`;
