import styled from 'styled-components'

export const ChartSectionWrapper = styled.div`
   display: flex;
  flex-direction: column;
  height: auto;
  width: 1250px;

  @media screen and (max-width: 1250px){
    width: 100%;
  }
`

export const ChartSectionHeaderWrapper = styled.div`
  display: flex;
  height: auto;
  width: 1250px;
  padding: 0px 30px;

  @media screen and (max-width: 1250px){
    width: 100%;
    height: auto;
  }

`
export const ChartSectionSubtitle = styled.h1`
  color: #1B523C;
  opacity: 0.65;
  display: flex;
  white-space: nowrap;
  font-size: 34px;
  margin-top: -15px;

  @media screen and (max-width: 1200px) {
    white-space: normal;
  }

  @media screen and (max-width: 520px) {
    white-space: normal;
    font-size: 28px;
  }
`



export const ChartSectionBodySection = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: auto;
  padding: 75px 0px 25px 0px;
  @media screen and (max-width: 1250px){
    width: 100%;
  }
`
export const Table = styled.table`
  border-collapse: collapse;

  border-radius: 5px;
  overflow: hidden;
`;

export const TableRow = styled.tr`
  //Custom Naming
`;

export const TableCell = styled.td`
  text-align: center;
  padding: 25px; 
  width: 175px;
  font-size: 20px;
  font-weight: 400;
  color: ${(cell) => cell.isHeader ? 'white' : 'rgba(27, 82, 60, 0.75)'};
  position: relative;
  background-color: ${(cell) =>
    cell.isEmpty
      ? "transparent"
      : cell.isHeader
      ? "rgba(27, 82, 60, 0.8)"
      : cell.isFeature
      ? "rgba(27, 82, 60, 0.25)"
      : "rgba(27, 82, 60, 0.15)"};
  border-bottom: 4px solid transparent; 


  &:nth-child(5) {
    background-color: #cde17e;
    font-weight: 700;
    color: rgb(27, 82, 60);
    border: 6px solid rgba(27, 82, 60, .9);
  }
  @media screen and (max-width: 850px){
    width: auto;
    font-size: 16px;
    padding: 0;
  }
  @media screen and (max-width: 450px){
    width: auto;
    font-size: 12px;
  }
`;




