import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages";
import TermsServicesPage from "./pages/termsOfServices";
import PrivacyPolicyPage from "./pages/privacyPolicy";
import Footer from "./components/General/Footer";
import React, {useState} from "react";
import BackgroundColor from './components/General/BackgroundColor';
import RequestPage from './pages/requestPage'

import Navbar from "./components/General/Navbar";
import Sidebar from "./components/General/Sidebar";

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Router>
      <BackgroundColor>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/termsOfServices" element={<TermsServicesPage />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicyPage />} />
        <Route path="/request" element={<RequestPage />} />
      </Routes>
      <Footer />
      </BackgroundColor>
    </Router>
  );
}

export default App;
