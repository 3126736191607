import styled from 'styled-components';

export const PrivacyPolicyContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    height: 100%;
    position: relative;
    z-index: 0;
    color: #1B523C;
    margin-bottom: auto;
  
  @media screen and (max-width: 600px) {
    height: auto;
    padding: 20px;
  }
`

export const PrivacyPolicyWrapper = styled.div`
    height: 100%;
    max-width: 1200px;
    padding: 0px 50px;
    display: flex;
    flex-direction: column;
    
    
    @media screen and (max-width: 900px){
        padding: 0px;
        width: 100%;
    }

    @media screen and (max-width: 600px){
       padding: 0px;
       width: 100%;
    }

`

export const PrivacyPolicyTitle = styled.h1`
    font-size: 32px;
    padding: 5px 40px;
    //white-space: nowrap;

    @media screen and (max-width: 900px){
        font-size: 27px;
    }

    @media screen and (max-width: 600px){
        font-size: 22px;
    }
`

export const PrivacyPolicyDivider = styled.div`
  width: 100%;
  height: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  background-color: #1B523C;

  @media screen and (max-width: 900px){
        height: 8px;
    }

    @media screen and (max-width: 600px){
        height: 6px;
    }
`

export const PrivacyPolicyContent = styled.p`
    font-size: 16px;
    padding: 7px 0;

    @media screen and (max-width: 900px){
        font-size: 13px;
    }
`


