import styled from "styled-components";

export const Form = styled.form`
  height: auto;
  width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #1b523c;
`;

export const FormRow = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: end;
  flex-direction: row;
  margin: 10px 0;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const FormGroup = styled.div`
  width: 100%;
  margin: 5px 10px;
  @media screen and (max-width: 600px) {
    margin: 5px 0;
  }
`;

export const Label = styled.h1`
  padding-left: 5px;
  font-size: 20px;
  display: inline;
`;
export const RequiredSignD = styled.h1`
  font-size: 15px;
  margin-left: 25px;
  display: inline;
`;

export const RequiredSign = () => {
  return <RequiredSignD>*Required</RequiredSignD>;
};

export const Input = styled.input`
  margin-top: 5px;
  width: 100%;
  padding: 12px;
  padding-left: 20px;
  border-style: none;
  background-color: rgba(27, 82, 60, 0.1); 
  border-radius: 10px;
  font-size: 16px;

  &::placeholder {
    color: rgba(27, 82, 60, 0.5); 
  }
`;

export const TextArea = styled.textarea`
  margin-top: 5px;
  width: 100%;
  resize: none;
  border-style: none;
  border-radius: 10px;
  padding: 15px;
  padding-left: 20px;
  font-size: 16px;
  min-height: 250px;
  background-color: rgba(27, 82, 60, 0.1);

  &::placeholder {
    color: rgba(27, 82, 60, 0.5); 
  }
`;

export const Button = styled.button`
  margin-top: 20px;
  border-radius: 12px;
  background-color: #1b523c;
  color: #e2e2e2;
  padding: 18px 60px;
  font-size: 18px;
  font-weight: 700;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  text-decoration: none;
  &:hover {
    transition: all 0.1s ease-in-out;
    background: #cde17e;
    color: #037568;
  }
  @media screen and (max-width: 600px) {
    margin-top: 10px;
    padding: 12px 54px;
    font-size: 14px;
  }
`;

export const ContactCompleteBody = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
position: relative;
min-height: 25vh;
padding-top: 100px;

`
export const ContactCompleteText= styled.p`
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  color: rgb(27, 82, 60); 

  margin-bottom: 20px;

  @media screen and (max-width: 600px) {
    font-size: 20px;
  }
`
