import React from "react";
import {
  FooterContainer,
  FooterLogo,
  FooterWrap,
  Img,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  FooterBtnWrapper,
  FooterBtn,
  FooterLinkRouter,
} from "./FooterElements";
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';



  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLogo to="/">
          <Img />
        </FooterLogo>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            
          {isHomePage && (
            <>
            <FooterLinkItems>
              <FooterLinkTitle>Resources</FooterLinkTitle>
              <FooterLink to="serverPrices" smooth={true} duration={500} spy={true} offset={-75}>Pricing</FooterLink>
              <FooterLink  to="about" smooth={true} duration={500} spy={true} offset={-75}>
                About Us
              </FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Support</FooterLinkTitle>
              <FooterLink to="faq" smooth={true} duration={500} spy={true} offset={-75}>FAQ</FooterLink>
            </FooterLinkItems>
            </>
          )}
            <FooterLinkItems>
              <FooterLinkTitle>Terms</FooterLinkTitle>
              <FooterLinkRouter to="/privacyPolicy">Privacy Policy</FooterLinkRouter>
              <FooterLinkRouter to="/termsOfServices">Terms of Services</FooterLinkRouter>
            </FooterLinkItems>
            {isHomePage && (
            <>
            <FooterLinkItems>
              <FooterLinkTitle></FooterLinkTitle>
              <FooterBtnWrapper>
                <FooterBtn to="contact" smooth={true} duration={500} spy={true} offset={-75}>Get in Touch</FooterBtn>
              </FooterBtnWrapper>
            </FooterLinkItems>
            </>
            )}
          </FooterLinksWrapper>
        </FooterLinksContainer>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
