import React from 'react'
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SidebarBtn,
  SidebarBtnLinkReq,
  // SidebarBtnLinkLogin,
} from './SidebarElements';

const Sidebar = ({isOpen, toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen}>
    <Icon onClick={toggle}>
        <CloseIcon/>
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to="home" onClick={toggle}>Home</SidebarLink>
          <SidebarLink to="serverPrices" onClick={toggle}>Servers &#38; Prices</SidebarLink>
          <SidebarLink to="about" onClick={toggle}>About</SidebarLink>
          <SidebarLink to="contact" onClick={toggle}>Contact</SidebarLink>
          <SidebarBtn>
            <SidebarBtnLinkReq to="/request" onClick={toggle}>Request</SidebarBtnLinkReq>
          </SidebarBtn>
          <SidebarBtn>
            {/* <SidebarBtnLinkLogin to="/login">Login</SidebarBtnLinkLogin> */}
          </SidebarBtn>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
