import styled from 'styled-components';
import blob from '../../icons/blobBackground.svg';

export const RequestPageWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 1250px;
  margin: 25px 0px;
  @media screen and (max-width: 600px){
    width: 100%;
  }
`

export const BlobBackground = styled.img.attrs({
  src: `${blob}`
})`
  height: 100%;  
  width: 95%;
  position: absolute;
  z-index:0;
`;

export const Form = styled.form`
  height: auto;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #1b523c;
  margin: 0px 15px;
  position: relative;
  z-index: 1;
  padding-bottom: 100px;
  padding-top: 90px;
`;

export const FormRow = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: end;
  flex-direction: row;
  margin: 15px 0;
  padding: 0px 20px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const FormGroup = styled.div`
  width: 100%;
  margin: 5px 20px;
  height: auto;
  @media screen and (max-width: 600px) {
    margin: 5px 0;
  }
`;

export const Label = styled.h1`
  padding-left: 5px;
  font-size: 20px;
  display: inline;
`;
export const RequiredSignD = styled.h1`
  font-size: 15px;
  margin-left: 25px;
  display: inline;
`;

export const RequiredSign = () => {
  return <RequiredSignD>*Required</RequiredSignD>;
};

export const Input = styled.input`
  margin-top: 5px;
  width: 100%;
  padding: 12px;
  padding-left: 20px;
  border-style: none;
  background-color: rgba(27, 82, 60, 0.1); 
  border-radius: 10px;
  font-size: 16px;

  &::placeholder {
    color: rgba(27, 82, 60, 0.75); 
  }
`;

export const DropDown = styled.select`
  display: block;
  width: 100%;
  margin-top: 5px;
  width: 100%;
  padding: 12px 20px;
  border-style: none;
  background-color: rgba(27, 82, 60, 0.1); 
  border-radius: 10px;
  font-size: 16px;
`

export const RadioOptionsWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: row;
justify-content: flex-start;
padding: 3px 7px;
flex-wrap: wrap;
`

export const RadioInputWrapper = styled.div`
  display: flex;
  width: auto;
  height: auto;
  padding: 10px 0px;
  padding-right: 75px;
  align-items: center;

  @media screen and (max-width: 650px){
    padding-right: 50px;
  }
`
export const RadioLabel = styled.label`
  font-size: 19px;
  font-weight: 400;
`

export const RadioInput = styled.input`
margin-right: 10px;
height: 20px;
width: 20px;
`

export const Button = styled.button`
  margin-top: 80px;
  border-radius: 12px;
  background-color: #1b523c;
  color: #e2e2e2;
  padding: 18px 60px;
  font-size: 18px;
  font-weight: 700;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  text-decoration: none;
  &:hover {
    transition: all 0.1s ease-in-out;
    background: #cde17e;
    color: #037568;
  }
  @media screen and (max-width: 600px) {
    margin-top: 25px;
    padding: 12px 54px;
    font-size: 16px;
  }
`;

export const RequestCompleteBody = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
position: relative;
min-height: 65vh;
padding-top: 100px;

`
export const RequestCompleteText= styled.p`
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  color: rgb(27, 82, 60); 

  margin-bottom: 20px;

  @media screen and (max-width: 600px) {
    font-size: 20px;
  }
`