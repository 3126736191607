import React from 'react'
import PageComponent from '../../General/PageComponent'
import {ServerSectionContainer,ProductCard} from '../ServicesPricesSection/ServicesPricesElements';
import { productData } from './productData.js';

const ServersPricingSection = () => {
  return (
    <PageComponent title='Servers and Pricing' id="serverPrices"
    body ={
      <ServerSectionContainer>
        {productData.map((servers) => (
        <ProductCard
          key={servers.id}
          img={servers.img}
          name={servers.name}
          cost={servers.cost}
          stats={servers.stats}
          storageClass={servers.storageClass} 
          storage={servers.storage} 
          memory={servers.memory}
          gpuCount={servers.gpuCount}
        />
      ))}
      </ServerSectionContainer>
    }/>
  )
}

export default ServersPricingSection