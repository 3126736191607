import styled from 'styled-components';
import {Link as LinkRouter} from 'react-router-dom'
import footerLogo from '../../../icons/footerLogo.svg'
import {Link as LinkScroll} from 'react-scroll'


export const FooterContainer = styled.footer`
    background-color: #1B523C;
`

export const FooterWrap = styled.div`
    padding: 10px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    max-width: 1250px; 
    margin: 0 auto;
    height: auto;
   
    @media screen and (max-width: 800px){
        flex-direction: column;
    }
`

export const FooterLogo = styled(LinkRouter)`
    display: flex;
    justify-self:center;
    align-items: center;
    cursor: pointer;
    margin-right: 15px;
    padding-bottom:30px;

    @media screen and (max-width: 800px){
       justify-content: center;
    }
`

export const Img = styled.img.attrs({
    src: `${footerLogo}`
  })`
    height: 60px;
    
  `;

export const FooterLinksContainer = styled.div`
    display: flex;
    justify-content:center;


    @media screen and (max-width: 480px){
        flex-direction: column;
        padding: 0px;
    }

`

export const FooterLinksWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin: 10px;
    width: 100%;


    @media screen and (max-width: 480px){
        flex-direction: column;
        margin: 0px;
    }
`

export const FooterLinkItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0px 15px;
    max-width: 100%;
    box-sizing: border-box;
    color: #fff;



    @media screen and (max-width: 480px) {
        margin: 0;
        padding: 10px;
        width: 100%;
    }
`

export const FooterLinkTitle = styled.h1`
    font-size: 14px;
    margin-bottom: 16px;
`

export const FooterLink = styled(LinkScroll)`
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 14px;

    &:hover {
        color: #000;
        transition: 0.3s ease-out;
    }
`
export const FooterLinkRouter = styled(LinkRouter)`
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 14px;

    &:hover {
        color: #000;
        transition: 0.3s ease-out;
    }
`

export const FooterBtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const FooterBtn = styled(LinkScroll)`
  border-radius: 10px;
  background: #b1cf5f;
  color: #037568;
  white-space: nowrap;
  padding: 12px 20px;
  font-size: 14px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  text-decoration: none;
  font-weight: 700;

  &:hover {
    transition: all 0.1s ease-in-out;
    background-color: #1b523c;
    color: #e2e2e2;
  }

  @media screen and (max-width: 600){
    padding: 10px 18px;
  font-size: 10px;
  }
`;
