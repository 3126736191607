import React, { useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormRow,
  RequiredSign,
  TextArea,
  ContactCompleteBody,
  ContactCompleteText
} from "./ContactElements";
import PageComponent from "../../General/PageComponent";

import { useForm } from '@formspree/react';

const ContactSection = () => {
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    company: "",
    website: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const [state, handleSubmit] = useForm("maygaarp");
  if (state.succeeded) {
    return (
      <PageComponent
    title="Contact Us"
    body={
 
        <ContactCompleteBody>
          <ContactCompleteText>We will contact you soon</ContactCompleteText>
          </ContactCompleteBody>
    }
    />
    );
  }
  return (
    <PageComponent
      title="Contact DLR"
      id="contact"
      body={
        <Form onSubmit={handleSubmit}>
          <FormRow>
            <FormGroup>
              <Label>Name</Label>
              <RequiredSign />
              <Input
                type="text"
                name="fname"
                value={formData.fname}
                onChange={handleChange}
                placeholder="First"
                required
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                name="lname"
                value={formData.lname}
                onChange={handleChange}
                placeholder="Last"
                required
              />
            </FormGroup>
          </FormRow>

          <FormRow>
            <FormGroup>
              <Label>Company</Label>
              <RequiredSign />
              <Input
                type="text"
                name="company"
                value={formData.company}
                onChange={handleChange}
                placeholder="Name"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>Website</Label>
              <Input
                type="text"
                name="website"
                value={formData.website}
                onChange={handleChange}
                placeholder="https://"
              />
            </FormGroup>
          </FormRow>

          <FormRow>
            <FormGroup>
              <Label>Email Address</Label>
              <RequiredSign />
              <Input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="abc@gmail.com"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>Phone Number</Label>
              <small style={{ marginLeft: 30 }}>+01-123-456-7890</small>
              <RequiredSign />
              <Input
                type="message"
                name="phone"
                placeholder="+01-123-456-789"
                pattern="+[0-9]{2}-[0-9]{3}-[0-9]{3}-[0-9]{4}"
                maxlength="16"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </FormRow>

          <FormRow>
            <FormGroup>
              <Label>How can we help you? </Label>
              <TextArea
                name="message"
                placeholder="Type something here"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </FormRow>
          <Button type="submit">Submit</Button>
        </Form>
      }
    />
  );
};

export default ContactSection;
