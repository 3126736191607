import React, {useState,useEffect} from 'react'
import PageComponent from '../../General/PageComponent'
import {AboutUsContainer, Icon, ContentWrapper, ImageWrapper, MeetTheTeamContainer, Paragraph, TextWrapper,TeamMemberCard } from './AboutUsElements'
import { teamMembers } from '../AboutUsSection/TeamMembers'


const AboutUsSection = () => {

  const [content, setContent] = useState('');

    useEffect(() => {
        async function fetchContent() {
            const response = await fetch(new URL('AboutUs.txt', import.meta.url));
            const text = await response.text();
            const paragraphs = text.split('\n');
            setContent(paragraphs);
        }
        fetchContent();
      }, []);

  return (
    <>
    <PageComponent title = 'About Us' id="about"
    body = {
       <AboutUsContainer>
        <ContentWrapper>
          <TextWrapper>
          {content.length > 0 ? (
        content.map((paragraph, index) => (
          <Paragraph key={index}>{paragraph}</Paragraph>
        ))
      ) : (
        <Paragraph>Loading...</Paragraph>
      )}
          </TextWrapper>
          <ImageWrapper>
            <Icon/>
          </ImageWrapper>
        </ContentWrapper>
       </AboutUsContainer>
    }
    />
    <PageComponent title = 'Meet the Team' dividerColor='transparent' 
    body = {
      <MeetTheTeamContainer>
      {teamMembers.map((member) => (
        <TeamMemberCard
          key={member.id}
          imageSrc={member.imageSrc}
          name={member.name}
          description={member.description}
        />
      ))}
    </MeetTheTeamContainer>
    }
    />
    </>
  )
}

export default AboutUsSection